import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { ChevronRightIcon } from '@heroicons/react/outline'

/*

<div className="py-4 bg-white">
                      <div className="max-w-7xl">
                        <div className="lg:text-center">
                          <h3 className="mt-2 text-1xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-2xl">
                          Solutions en cas de fissures
                          </h3>
                          <ul className="mt-4 text-lg leading-6 text-gray-900 lg:text-left">
                            <li><ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Expertises indépendante</li>
                            <li><ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Etude de sol</li>
                            <li><ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Reprise en sous-oeuvre</li>
                            <li><ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Renforcement structurel</li>
                            <li><ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Embellissement</li>
                          </ul>

                          <a
                            href="/contact/"
                            className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-amber-600 hover:bg-amber-700 sm:w-auto"
                            >
                            Trouvez un pro près de chez vous
                            </a>
                        </div>
                      </div>
                    </div>


*/

export default function Sidebar() {
  return (

            <div className="bg-white ">


                    <div className="flex flex-col flex-grow bg-green-500 rounded-lg text-center py-4 px-4 sm:py-8 sm:px-6 lg:px-8">
                        <h3 className="text-xl font-bold text-white sm:text-2xl">
                        <span className="block">Solution travaux</span>
                        </h3>
                        <p className="mt-4 text-lg leading-6 text-green-200">
                        Construction, rénovation, agencement... Entrer en relation avec des artisans et entreprises du bâtiment
                        </p>
                        <p className="mt-4 leading-6 text-green-200">
                          <a href="/maison/contact/">
                            <StaticImage
                              className="bg-white rounded-md"
                              src="../../images/sidebar/Maison.jpg" 
                              alt="Fissuration"
                            />
                          </a>
                        </p>
                        <p className="mt-4 text-right text-lg leading-6 text-green-200">
                          <a href="/maison/contact/" className="underline">
                            En savoir plus
                          </a>
                        </p>
                    </div>


                    <div className="py-4 bg-white">
                      <div className="max-w-7xl">
                        <div className="lg:text-center">
                          <h3 className="mt-2 text-1xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-2xl">
                          Media Kit Koudepouce
                          </h3>
                          <p className="mt-4 text-lg leading-6 text-gray-900 lg:text-left">
                          Vous êtes un pro du bâtiment.
                          </p>
                          <p className="mt-4 text-lg leading-6 text-gray-900 lg:text-left">
                          Bandeau, encart promotionnel, publi-information... <br />
                          <a 
                            href="/contact/"
                            className="text-green-500 underline"
                          >
                            Renseignez-vous pour apparaître sur nos supports digitaux
                          </a>
                          </p>
                        </div>
                      </div>
                    </div>


                    
                    
                    <div className="py-4 bg-white">
                      <div className="max-w-7xl">
                        <div className="lg:text-center">
                          <h3 className="mt-2 text-1xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-2xl">
                          Articles et liens
                          </h3>
                          <p className="mt-4 text-lg leading-6 text-gray-900 lg:text-left">
                            <a 
                              href="/maison/amenagement-interieur/"
                              className="text-green-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Aménagement intérieur
                            </a>
                            <a 
                              href="/maison/decoration-interieure/"
                              className="text-green-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Décoration intérieure
                            </a>
                            <a 
                              href="/maison/nettoyage-menage-domicile/"
                              className="text-green-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Nettoyage ménage domicile
                            </a>
                            <a 
                              href="/maison/travaux-bricolage-domicile/"
                              className="text-green-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Travaux bricolage domicile
                            </a>
                            <a 
                              href="/maison/peinture-interieure-logement/"
                              className="text-green-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Peinture intérieure logement
                            </a>
                            <a 
                              href="/maison/service-travaux/"
                              className="text-green-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Service travaux
                            </a>
                            <a 
                              href="/maison/entretien-espaces-verts/"
                              className="text-green-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Entretien espaces verts
                            </a>
                            <a 
                              href="/maison/demenagement/"
                              className="text-green-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Déménagement
                            </a>
                            <a 
                              href="/maison/surveillance-maison-vacances/"
                              className="text-green-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Surveillance maison vacances
                            </a>
                            <a 
                              href="/maison/malfacons-construction/"
                              className="text-green-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Malfaçons construction
                            </a>
                            <a 
                              href="/maison/chauffage/"
                              className="text-green-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Chauffage
                            </a>
                            <a 
                              href="/maison/plomberie/"
                              className="text-green-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Plomberie
                            </a>
                            <a 
                              href="/maison/electricite/"
                              className="text-green-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Electricité
                            </a>
                            <a 
                              href="/maison/alarme/"
                              className="text-green-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Alarme
                            </a>
                            <a 
                              href="/maison/domotique/"
                              className="text-green-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Domotique
                            </a>
                            <a 
                              href="/maison/electromenager/"
                              className="text-green-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Electroménager
                            </a>
                            <a 
                              href="/maison/choisir-veranda/"
                              className="text-green-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Véranda
                            </a>
                            <a 
                              href="/maison/gros-oeuvre/"
                              className="text-green-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Gros oeuvre
                            </a>
                            <a 
                              href="/maison/toiture/"
                              className="text-green-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Toiture
                            </a>
                            <a 
                              href="/maison/isolation/"
                              className="text-green-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Isolation
                            </a>
                            <a 
                              href="/maison/humidite/"
                              className="text-green-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Humidité
                            </a>
                            <a 
                              href="/maison/fissures-maison/"
                              className="text-green-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Fissures
                            </a>
                            <a 
                              href="/maison/etude-sol-maison/"
                              className="text-green-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Etude de sol
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    

                    
            </div>



        )
    }